import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import { Spring, animated, config } from "react-spring"
import VisibilityWrapper from "../../containers/VisibilityWrapper"
import Paragraph from "../Paragraph"
import Image from "gatsby-image"

export default class PeopleCard extends PureComponent {
  render() {
    const { text, image, className } = this.props

    return (
      <VisibilityWrapper className={styles.hiddenProperties}>
        <Spring
          native
          from={{ transform: "translateY(10%)", opacity: 0 }}
          to={{ transform: "translateY(0%)", opacity: 1 }}
          config={config.slow}
        >
          {props => (
            <animated.div style={props} className={`${styles.container} ${className}`}>
              <Image
                sizes={image.childImageSharp.fluid}
                className={styles.avatar}
                imgStyle={{ borderRadius: "100%" }}
              />
              <Paragraph html={text} className={styles.text} />
            </animated.div>
          )}
        </Spring>
      </VisibilityWrapper>
    )
  }
}
