import React, { PureComponent } from "react"
import Title from "../Title"
import styles from "./styles.module.scss"

export default class Tab extends PureComponent {
  handleClick = () => {
    this.props.onClick({ id: this.props.id })
  }

  render() {
    const { title, hasShadow, className, selected, style } = this.props

    return (
      <li className={className} style={style} onClick={this.handleClick}>
        <Title
          title={title}
          className={`${styles.title} ${selected && styles.selected} ${hasShadow && styles.shadow}`}
          hasShadow={hasShadow}
        />
      </li>
    )
  }
}
