import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import SocialMediaItem from "../SocialMediaItem"

export default class Footer extends PureComponent {
  render() {
    const { item } = this.props

    return (
      <footer className={styles.footer}>
        <SocialMediaItem item={item} />
        <div className={styles.iconCredit}>
          Icons made by{" "}
          <a href="https://www.freepik.com/" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>{" "}
          is licensed by{" "}
          <a
            href="http://creativecommons.org/licenses/by/3.0/"
            title="Creative Commons BY 3.0"
            target="_blank"
            rel="noopener noreferrer"
          >
            CC 3.0 BY
          </a>
        </div>
        <div className={styles.copyright}>Christophe Dalmasso © {new Date().getFullYear()}</div>
      </footer>
    )
  }
}
