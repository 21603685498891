import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import Paragraph from "../Paragraph"
import Image from "gatsby-image"

export default class Row extends PureComponent {
  state = { open: false }

  handleClickRow = () => {
    this.setState({ open: !this.state.open })
  }

  renderButton = () => {
    return this.state.open ? (
      <div className={styles.minus}>-</div>
    ) : (
      <div className={styles.plus}>+</div>
    )
  }

  renderContent = () => {
    const { image, text } = this.props

    return this.state.open ? (
      <div className={styles.textWrapper}>
        {!!image && <Image sizes={image} className={styles.image} />}
        <Paragraph html={text} />
      </div>
    ) : null
  }

  render() {
    const { title, className } = this.props

    return (
      <div className={className}>
        <div onClick={this.handleClickRow} className={styles.titleWrapper}>
          <h2 className={styles.title}>{title}</h2>
          {this.renderButton()}
        </div>
        {this.renderContent()}
      </div>
    )
  }
}
