import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import Input from "../../components/Input"
import TextInput from "../../components/TextInput"
import SubmitButton from "../../components/SubmitButton"
import Title from "../../components/Title"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const initialState = {
  prénom: "",
  nom: "",
  email: "",
  téléphone: "",
  ville: "",
  pays: "",
  message: ""
}

export default class Form extends PureComponent {
  state = {
    ...initialState
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "contact",
        ...this.state
      })
    })
      .then(() => this.setState({ ...initialState, sent: true }))
      .catch(error => alert(error))

    e.preventDefault()
  }

  render() {
    const { content } = this.props
    const { prénom, nom, email, téléphone, ville, pays, message } = this.state
    return (
      <section id="contact" className={styles.container}>
        <div className={styles.wrapper}>
          <Title className={styles.title} title="Contact" />
          <form
            name="contact"
            method="POST"
            netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={this.handleSubmit}
          >
            <input type="hidden" name="form-name" value="contact" />
            <div className={styles.nameWrapper}>
              <Input
                className={styles.nameInput}
                label={content.first_name}
                name="prénom"
                placeholder={content.first_namePlaceholder}
                required={true}
                value={prénom}
                type="text"
                onChange={this.handleChange}
              />
              <Input
                className={styles.nameInput}
                label={content.last_name}
                name="nom"
                placeholder={content.last_namePlaceholder}
                required={true}
                value={nom}
                type="text"
                onChange={this.handleChange}
              />
            </div>
            <Input
              className={styles.input}
              label={content.email}
              name="email"
              required={true}
              placeholder={content.emailPlaceholder}
              value={email}
              type="email"
              onChange={this.handleChange}
            />
            <Input
              className={styles.input}
              label={content.phone}
              name="téléphone"
              required={true}
              placeholder={content.phonePlaceholder}
              value={téléphone}
              type="tel"
              onChange={this.handleChange}
            />
            <Input
              className={styles.input}
              label={content.city}
              name="ville"
              placeholder={content.cityPlaceholder}
              value={ville}
              type="text"
              onChange={this.handleChange}
            />
            <Input
              className={styles.input}
              label={content.country}
              name="pays"
              placeholder={content.countryPlaceholder}
              value={pays}
              type="text"
              onChange={this.handleChange}
            />
            <TextInput
              className={styles.input}
              label={content.message}
              name="message"
              required={true}
              placeholder={content.messagePlaceholder}
              value={message}
              type="text"
              onChange={this.handleChange}
            />
            <div className={styles.buttonWrapper}>
              <SubmitButton
                submitMessage={content.submit}
                successMessage={content.sent}
                isSent={this.state.sent}
              />
            </div>
          </form>
        </div>
      </section>
    )
  }
}
