export default {
  menuItems: [
    { id: "home", label: "Acceuil" },
    { id: "person", label: "Personnes" },
    { id: "about", label: "L'enquête" },
    { id: "contact", label: "Contact" }
  ],
  youtube: {
    url: "https://www.youtube.com/channel/UCGIdwkvKOd-Eqd1N4vpBLbg",
    id: "youtube",
    alt: "youtube logo"
  },
  options: [
    { id: "trial", label: "Les procès" },
    { id: "redo", label: "Refaire l'enquête" },
    { id: "news", label: "La presse" }
  ],
  message: "Message",
  messagePlaceholder: "Votre message ici...",
  first_name: "Prénom",
  last_name: "Nom",
  email: "Adresse courriel",
  submit: "Envoyer le message",
  first_namePlaceholder: "Votre prénom",
  last_namePlaceholder: "Votre nom",
  emailPlaceholder: "courriel@gmail.com",
  city: "Ville",
  cityPlaceholder: "Votre ville",
  country: "Pays",
  countryPlaceholder: "Votre pays",
  phone: "Téléphone",
  phonePlaceholder: "55 55 55 55 55",
  sent: "Message envoyé"
}
