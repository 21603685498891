import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import VisibilitySensor from "react-visibility-sensor"

export default class VisibilityWrapper extends PureComponent {
  state = { visible: false }

  onVisibilitySensorChange = isVisible => {
    if (isVisible) {
      this.setState({ visible: true })
    }
  }

  render() {
    const { className, minTopValue } = this.props

    return (
      <VisibilitySensor
        partialVisibility
        minTopValue={minTopValue || 200}
        onChange={this.onVisibilitySensorChange}
      >
        {this.state.visible ? (
          this.props.children
        ) : (
          <div className={`${styles.hidden} ${className}`}>&nbsp;</div>
        )}
      </VisibilitySensor>
    )
  }
}
