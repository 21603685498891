import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import youtube from "../../images/youtube.png"

export default class SocialMediaItem extends PureComponent {
  render() {
    const { item, className } = this.props

    return (
      <a className={className} href={item.url} target="_blank" rel="noreferrer">
        <img src={youtube} alt={item.alt} className={styles.socialMediaIcon} />
      </a>
    )
  }
}
