import React from "react"
import styles from "./styles.module.scss"

const Label = ({ labelFor, label, required, className }) => (
  <label className={`${styles.container} ${className}`} htmlFor={labelFor}>
    {label}
    {required && <span className={styles.required}>*</span>}
  </label>
)

export default Label
