import React from "react"
import styles from "./styles.module.scss"
import { animated } from "react-spring"

const Paragraph = ({ className, style, html }) => {
  return (
    <animated.div
      style={style}
      className={`${styles.container} ${className}`}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default Paragraph
