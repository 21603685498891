import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import MenuItem from "../MenuItem"

export default class Menu extends PureComponent {
  handleClick = ({ id }) => {
    this.props.onClick({ id })
  }

  renderMenuItems = () => {
    const { items, isSafari } = this.props

    return items.map(({ id, label }, i) => (
      <MenuItem
        key={`${label}${i}`}
        label={label}
        id={id}
        isSafari={isSafari}
        isHome={i === 0}
        onClick={this.handleClick}
      />
    ))
  }

  render() {
    return <ul className={`${styles.menu} ${this.props.className}`}>{this.renderMenuItems()}</ul>
  }
}
