import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import Menu from "../../components/Menu"
import SocialMediaItem from "../../components/SocialMediaItem"

export default class MenuItem extends PureComponent {
  handleClickMenuItem = ({ id }) => {
    this.props.onClick({ id })
  }

  render() {
    const { className, menuItems, item, isSafari } = this.props

    return (
      <header className={`${styles.container} ${className}`}>
        <Menu
          items={menuItems}
          isSafari={isSafari}
          onClick={this.handleClickMenuItem}
          className={styles.menu}
        />
        <SocialMediaItem item={item} className={styles.social} />
      </header>
    )
  }
}
