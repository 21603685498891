import React, { PureComponent, Fragment } from "react"
import { graphql } from "gatsby"
import styles from "./styles.module.scss"
import contentFr from "../content/content.fr"
import Layout from "../layout"
import SectionBanner from "../components/SectionBanner"
import SectionMain from "../components/SectionMain"
import SectionPeople from "../components/SectionPeople"
import ContactForm from "../containers/ContactForm"
import { Helmet } from "react-helmet"
import Header from "../components/Header"
import Footer from "../components/Footer"
import "typeface-roboto"
import "typeface-permanent-marker"
import "typeface-lato"

export default class HomePage extends PureComponent {
  state = {
    isDark: false,
    content: {
      redo: [],
      news: [],
      trial: []
    },
    isLoaded: false
  }

  handleScroll = e => {
    const { scrollTop } = document.scrollingElement || document.documentElement

    if (scrollTop <= 10) {
      this.setState({
        isDark: false
      })
    } else {
      this.setState({
        isDark: true
      })
    }
  }

  transformType = (group, type) => {
    const items = group.find(item => item.fieldValue === type)

    if (!items) {
      return []
    }

    return items.edges[0].node.frontmatter.entries.map(({ title, text, image, alt }) => ({
      title,
      text,
      image: image ? image.childImageSharp.fluid : null,
      alt
    }))
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.handleScroll, true)

    const { group } = this.props.data.allMarkdownRemark
    const main = group.find(item => item.fieldValue === "prologue")
    const { prologue, people } = main.edges[0].node.frontmatter
    const redo = this.transformType(group, "redo")
    const news = this.transformType(group, "news")
    const trial = this.transformType(group, "trial")

    const isSafari = !!window["safari"]

    this.setState({
      content: {
        ...contentFr,
        prologue,
        people,
        redo,
        news,
        trial,
        isSafari
      },
      isLoaded: true
    })
  }

  componentWillUnmount = () => {
    window.removeEventListener("scroll", this.handleScroll, true)
  }

  render() {
    const { content, isLoaded } = this.state

    return (
      <Layout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Christophe Dalmasso</title>
          <meta name="description" content="Fiasco de la justive française" />
          <meta name="keywords" content="Affaire, meurtre, christophe, dalmasso, lucie, renée" />
          <html lang="fr" />
        </Helmet>

        <form name="contact" method="POST" netlify="true" data-netlify-honeypot="bot-field" hidden>
          <input type="hidden" name="form-name" value="contact" />
          <input name="prénom" type="text" />
          <input name="nom" type="text" />
          <input name="email" type="email" />
          <input name="téléphone" type="tel" />
          <input name="ville" type="text" />
          <input name="pays" type="text" />
          <textarea name="message" type="text" />
          <input type="submit" />
        </form>

        {isLoaded && (
          <Fragment>
            <section id="home" className={styles.home} />
            <Header
              menuItems={content.menuItems}
              item={content.youtube}
              isDark={this.state.isDark}
              isSafari={content.isSafari}
              className={styles.header}
            />
            <SectionBanner title={content.title} />
            <SectionPeople people={content.people} />
            <SectionMain content={content} className={styles.sectionContent} />
            <ContactForm content={content} />
            <Footer terms={content.terms} item={content.youtube} />
          </Fragment>
        )}
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query HomePage {
    allMarkdownRemark {
      group(field: frontmatter___type) {
        fieldValue
        edges {
          node {
            html
            frontmatter {
              prologue
              entries {
                title
                text
                alt
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              people {
                text
                image {
                  childImageSharp {
                    fluid(maxWidth: 1000) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
