import React, { PureComponent } from "react"
import styles from "./styles.module.scss"

export default class SubmitButton extends PureComponent {
  render() {
    const { submitMessage, successMessage, isSent } = this.props

    return (
      <input
        className={`${styles.input} ${isSent && styles.sent}`}
        type="submit"
        value={isSent ? successMessage : submitMessage}
      />
    )
  }
}
