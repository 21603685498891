import React from "react"
import styles from "./styles.module.scss"
import Label from "../Label"

const Input = ({ type, name, value, label, placeholder, required, onChange, className }) => (
  <div className={`${styles.container} ${className}`}>
    <Label labelFor={name} label={label} required={required} className={styles.label} />
    <input
      className={styles.input}
      type={type}
      name={name}
      id={name}
      value={value}
      placeholder={placeholder}
      required={required}
      onChange={onChange}
    />
  </div>
)

export default Input
