import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import Scrollchor from "react-scrollchor"
import Link from "gatsby-link"
import home from "../../images/home.svg"

export default class MenuItem extends PureComponent {
  render() {
    const { id, label, isSafari, isHome, className } = this.props

    return (
      <li className={className}>
        {!isSafari ? (
          <Scrollchor to={`#${id}`} className={styles.menuItem}>
            {isHome ? <img src={home} alt="home icon" className={styles.icon} /> : label}
          </Scrollchor>
        ) : (
          <Link className={styles.menuItem} to={`#${id}`}>
            {isHome ? <img src={home} alt="home icon" className={styles.icon} /> : label}
          </Link>
        )}
      </li>
    )
  }
}
