import React, { PureComponent } from "react"
import Title from "../Title"
import styles from "./styles.module.scss"
import { Spring, animated, Trail, config } from "react-spring"

const items = [
  { text: "L'affaire", className: "wrapper1", key: 1 },
  { text: "Christophe", className: "wrapper2", key: 2 },
  { text: "Dalmasso", className: "wrapper3", key: 3 }
]

export default class SectionBanner extends PureComponent {
  render() {
    const { className } = this.props

    return (
      <section className={`${styles.container} ${className}`}>
        <Spring from={{ opacity: 0 }} to={{ opacity: 1 }} config={{ tension: 20 }}>
          {props => (
            <div style={props} className={styles.backgroundImage}>
              <div className={styles.titleWrapper}>
                <Spring
                  from={{ opacity: 0 }}
                  to={{ opacity: 1 }}
                  config={config.default}
                  delay="2000"
                >
                  {props => <div style={props} className={styles.blood} />}
                </Spring>
                <Trail
                  native
                  items={items}
                  keys={item => item.key}
                  from={{
                    transform: "translateX(-10%) skew(-20deg)",
                    opacity: 0
                  }}
                  to={{
                    transform: "translateX(-20%) skew(-20deg)",
                    opacity: 1
                  }}
                  delay="800"
                  config={{ tension: 75, friction: 50 }}
                >
                  {item => props => (
                    <animated.div className={styles[item.className]} style={props}>
                      <Title className={styles.title} title={item.text} hasShadow />
                    </animated.div>
                  )}
                </Trail>
                <Spring
                  from={{
                    transform: "scale(1.5) rotate(-3deg)",
                    opacity: 0
                  }}
                  to={{
                    transform: "scale(1) rotate(-3deg)",
                    opacity: 1
                  }}
                  delay="3000"
                >
                  {props => (
                    <div style={props} className={styles.subtitleWrapper}>
                      <h2 className={styles.subtitle}>Fiasco de la justice française</h2>
                      <Spring
                        from={{
                          width: "0%"
                        }}
                        to={{
                          width: "105%"
                        }}
                        delay="3500"
                        config={{ tension: 50 }}
                      >
                        {props => (
                          <div style={props} className={styles.underline}>
                            &nbsp;
                          </div>
                        )}
                      </Spring>
                    </div>
                  )}
                </Spring>
              </div>
            </div>
          )}
        </Spring>
      </section>
    )
  }
}
