import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import Tab from "../Tab"

export default class Tabs extends PureComponent {
  handleClick = ({ id }) => {
    this.props.onClick({ id })
  }

  renderTabs = () => {
    const { options, selected } = this.props

    return options.map(({ id, label }) => (
      <Tab title={label} id={id} key={id} onClick={this.handleClick} hasShadow={id === selected} selected={id === selected} />
    ))
  }

  render() {
    const { className } = this.props

    return <ul className={`${styles.container} ${className}`}>{this.renderTabs()}</ul>
  }
}
