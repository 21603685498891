import React, {PureComponent} from "react"
import Title from "../Title"
import styles from "./styles.module.scss"
import Paragraph from "../../components/Paragraph"
import Rows from "../Rows"
import Tabs from "../Tabs"

export default class SectionMain extends PureComponent {
    state = {
        selected: "redo"
    }

    handleClick = ({id}) => {
        this.setState({selected: id})
    }

    renderVideos() {
        const urls = ['0osM9hf2XmM', 'Azy_UgDAfCs', 'V9ZC-QE6n30', '7DXP_2mirns', 'Qh1iikKPiOQ']
        return urls.map(id => (
            <iframe style={{marginTop: '10rem', width: '100%', minHeight: '40rem'}}
                    src={`https://www.youtube.com/embed/${id}`} frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
            />))
    }

    render() {
        const {content, className} = this.props
        const {selected} = this.state
        const entries = content[selected]

        return (
            <section id="about" className={`${styles.container} ${className}`}>
                <div className={styles.wrapper}>
                    <div className={styles.mainTitleWrapper}>
                        <Title className={styles.title} title="L'enquête" hasShadow/>
                    </div>
                    <div className={styles.paragraphWrapper}>
                        <Paragraph html={content.prologue} className={styles.paragraph}/>
                    </div>

                    {this.renderVideos()}

                    <iframe style={{marginTop: '10rem'}} width="100%" height="150" scrolling="no" frameBorder="no"
                            allow="autoplay"
                            src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/843669028&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>

                    <iframe allow="autoplay *; encrypted-media *; fullscreen *" frameBorder="0" height="175"
                            style={{
                                marginTop: '10rem',
                                width: '100%',
                                maxWidth: '660px',
                                overflow: 'hidden',
                                background: 'transparent',
                            }}
                            width="100%"
                            sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
                            src="https://embed.podcasts.apple.com/fr/podcast/laffaire-dalmasso/id389542173?i=1000501983163"></iframe>

                    <Tabs
                        options={content.options}
                        onClick={this.handleClick}
                        selected={selected}
                        className={styles.tabs}
                    />
                    <Rows entries={entries} className={styles.rows}/>
                </div>
            </section>
        )
    }
}
