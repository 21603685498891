import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import Row from "../Row"

export default class Rows extends PureComponent {
  renderRows = () => {
    const { entries } = this.props

    return entries.map(({ title, text, image }) => (
      <li key={title}>
        <Row title={title} text={text} image={image} />
      </li>
    ))
  }

  render() {
    const { className } = this.props

    return <ul className={`${styles.container} ${className}`}>{this.renderRows()}</ul>
  }
}
