import React, { PureComponent } from "react"
import styles from "./styles.module.scss"

export default class Title extends PureComponent {
  render() {
    const { title, hasShadow, className, style } = this.props
    return (
      <div className={`${styles.title} ${hasShadow && styles.shadow} ${className}`} style={style}>
        {title.toUpperCase()}
      </div>
    )
  }
}
