import React, { PureComponent } from "react"
import styles from "./styles.module.scss"
import PeopleCard from "../PeopleCard"

export default class SectionPeople extends PureComponent {
  renderPeople() {
    const { people } = this.props

    return people.map(({ text, image }, i) => (
      <PeopleCard
        key={`i${i}`}
        text={text}
        image={image}
        className={i !== people.length - 1 ? styles.card : null}
      />
    ))
  }

  render() {
    const { className } = this.props

    return (
      <section id="person" className={`${styles.container} ${className}`}>
        {this.renderPeople()}
      </section>
    )
  }
}
